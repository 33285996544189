// @flow
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { calculateDaysToDate } from '../../../utils/functions';
import useAuth0Credentials from '../../../hooks/useAuth0Credentials';
import { BusinessUnitService } from '../../../service';
import { useDispatch, useSelector } from 'react-redux';
import { updatePudoData } from '../../../redux/pudo/pudoSlice';

const BlockingModal = (): ReactElement => {
  const { userId } = useAuth0Credentials();
  const businesService = new BusinessUnitService();

  const history = useHistory();
  const [show, setShow] = useState(false);
  const pathName = useHistory().location.pathname;

  const dispatch = useDispatch();
  const pudoResponse = useSelector((state: any) => state.pudoResponse)
  const statusLogin = {
    activeLogin: pudoResponse.active_login,
    expiredLogin: pudoResponse.password_expired,
    nextPassword: pudoResponse.next_password_update,
    lastPassword: pudoResponse.last_password_update,
  };

  const [modalInfo, setModalInfo] = useState({
    modalTitle: '',
    modalBody: '',
    modalButton: (
      <Button
        variant="secondary"
        onClick={() => history.push('/account/logout')}>
        Voltar para a página de login
      </Button>
    ),
  });
  
  const getStatusAccount = () => {
    console.log('get status account');
    businesService
      .getPudo(userId)
      .then((res: any) => {
        const { data } = res;
        if (data) {
          dispatch(updatePudoData({
            ...data,
            alreadyChecked: true,
          }));
        }
      })
      .catch(() => {
        dispatch(updatePudoData());
      })
  };
  
  useEffect(() => {
    if(pudoResponse.alreadyChecked) return
    getStatusAccount();
  }, []);

  useEffect(() => {
    const timeToExpired = calculateDaysToDate(statusLogin?.nextPassword);

    setShow(
      !statusLogin?.activeLogin ||
        (statusLogin?.expiredLogin && pathName !== '/profile/change-password') ||
        (timeToExpired <= 5 && pathName !== '/profile/change-password')
    );

    console.log( timeToExpired <= 5)

    if (statusLogin.expiredLogin && !statusLogin.activeLogin) {
      setModalInfo({
        modalTitle:
          'Sua Senha Expirou E O Usuário Está Bloqueado Por Inatividade.',
        modalBody: `Esse usuário se encontra bloqueado por inatividade. Contate o suporte para que o ${''}
          desbloqueio seja realizado. Depois realize a alteração de senha.`,
        modalButton: (
          <Button
            variant="secondary"
            onClick={() => history.push('/account/logout')}>
            Voltar para a página de login
          </Button>
        ),
      });
    } else if (statusLogin.expiredLogin) {
      setModalInfo({
        modalTitle: 'Sua senha expirou.',
        modalBody:
          'Solicite a "Redefinição de senha" para continuar operando normalmente',
        modalButton: (
          <Button
            variant="secondary"
            onClick={() => history.push('/profile/change-password')}>
            Redefinir Senha
          </Button>
        ),
      });
    } else if (!statusLogin.activeLogin) {
      setModalInfo({
        modalTitle: 'Usuário Bloqueado Por Inatividade',
        modalBody:
          'Esse usuário se encontra bloqueado por inatividade. Contate o suporte para que o desbloqueio seja realizado.',
        modalButton: (
          <Button
            variant="secondary"
            onClick={() => history.push('/account/logout')}>
            Voltar para a página de login
          </Button>
        ),
      });
    } else if (timeToExpired <= 5) {
      setModalInfo({
        modalTitle: 'Sua senha irá expirar.',
        modalBody:
          'Realize a alteração para que seu usuário não seja bloqueado.',
        modalButton: (
          <Button variant="secondary" onClick={() => setShow(false)}>
            Continuar Navegando
          </Button>
        ),
      });
    }

    return () => {
      // Ao desmontar o componente
    };
  }, [
    statusLogin.activeLogin,
    statusLogin.expiredLogin,
    statusLogin?.nextPassword,
  ]);

  return (
    <Modal show={show}>
      <Modal.Header
        onHide={() => console.log('Bloqueado')}
        className={classNames('modal-colored-header', `bg-warning`)}>
        <h4 className="modal-title">{modalInfo.modalTitle}</h4>
      </Modal.Header>
      <Modal.Body>
        <p className="mt-3">{modalInfo.modalBody}</p>
      </Modal.Body>
      <Modal.Footer>
        <>{modalInfo.modalButton && modalInfo.modalButton}</>
      </Modal.Footer>
    </Modal>
  );
};

export default BlockingModal;
