// @flow
import { combineReducers } from 'redux';

import Layout from './layout/reducers';
import { GetPudoResponseReducer } from './pudo/pudoSlice';

export default (combineReducers({
    Layout,
    pudoResponse: GetPudoResponseReducer,
}));
