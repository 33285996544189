import { jwtDecode } from 'jwt-decode';
import { compareDesc, fromUnixTime } from 'date-fns';

export const getToken = () => {
  const token = localStorage.getItem('token');

  if (token) {
    let isExpired = false;

    const decodedToken = jwtDecode(token);

    const dateNow = new Date().getTime();

    const expDate = decodedToken?.exp || 0;

    if (compareDesc(fromUnixTime(expDate), dateNow) === 1) isExpired = true;

    if (isExpired) {
      window.location.href = `${window.location.origin}/account/logout`;
    } else {
      return `Bearer ${token}`;
    }
  } else {
    window.location.href = `${window.location.origin}/account/logout`;
  }
  return null;
};
