import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import { EnumEvent, EnumScenario } from '../enum';
import { getToken } from '../config/GetToken';

const FormatDateUtc = (date: any, format = 'DD/MM/YYYY HH:mm:ss') => {
  return date !== ''
    ? moment.utc(date).local().format(format)
    : 'Data não informada';
};

const formatDate = (date: Date) => {
  const d = new Date(date);
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};

const isDeliveredToCarrier = (events: any) => {
  return !!events.find((x: any) => x.name === EnumEvent.DeliveredToCarrier);
};

const validateScenarioDevolutionDropped = (scenario: number) => {
  return (
    EnumScenario.Scenario1 === scenario ||
    EnumScenario.Scenario2 === scenario ||
    EnumScenario.Scenario6 === scenario
  );
};

function validarCPF(cpf: string): boolean {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += Number(cpf.charAt(i)) * (10 - i); // eslint-disable-line
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += Number(cpf.charAt(i)) * (11 - i); // eslint-disable-line
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(10))) return false;
  return true;
}

const capitalize = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const removeElement = (array: any, key: string, value: any) => {
  return array.filter((item: any) => key in item && item[key] !== value);
};

const extractValueObjects = (array: any, key: string) => {
  return array.map((element: any) => element[key]);
};

const verifyIsAdm = () => {
  const token = localStorage.getItem('token');
  if (getToken() && token) {
    const tokenDecoded = jwtDecode(token) as any;
    const claims: string[] | undefined =
      tokenDecoded?.[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ];
    const isAdm =
      claims?.find((claim) => claim === 'business-unit-admin') !== undefined;

    return isAdm;
  }
  return false;
};

const calculateDaysToDate = (dataString: string): number => {
  const dataAtual: Date = new Date();
  const dataFornecida: Date = new Date(dataString);

  const diferencaEmMilissegundos: number =
    dataFornecida.getTime() - dataAtual.getTime();

  const diferencaEmDias: number = Math.ceil(
    diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
  );

  return diferencaEmDias;
};

const removeCharacters = (value: string): string => {
  return value
    .toString()
    .split('.')
    .join('')
    .split('-')
    .join('')
    .split('/')
    .join('')
    .split('(')
    .join('')
    .split(')')
    .join('')
    .split(' ')
    .join('')
    .split('R$')
    .join('');
};

export {
  FormatDateUtc,
  formatDate,
  isDeliveredToCarrier,
  validateScenarioDevolutionDropped,
  validarCPF,
  capitalize,
  removeElement,
  extractValueObjects,
  verifyIsAdm,
  calculateDaysToDate,
  removeCharacters,
};
