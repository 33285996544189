import HttpClientService from '../../config/HttpClient';

export default class HttpClientsService {
  api: HttpClientService;

  constructor() {
    this.api = new HttpClientService();
  }

  getMarketplaceAcceptOrderReturn = (id: string) => {
    return this.api.get(`v1/marketplaces/${id}/accept_order_return`);
  };

  getClientAcceptOrderReturn = (id: string) => {
    return this.api.get(`v1/clients/${id}/accept_order_return`);
  };

  postRequireCollectionDrop = (payload: any) => {
    return this.api.post(
      `v1/marketplaces/require_data_collection_on_drop`,
      payload
    );
  };
}
