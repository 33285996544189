import axios, { type AxiosInstance } from 'axios';
import { UserLoginType } from '../enum';
import { Environment, getCurrentApiEnv } from './Environment';
import { getToken } from './GetToken';
import { verifyIsAdm } from '../utils/functions';

export default class HttpBusinessUnitService {
  service: AxiosInstance;

  constructor() {
    const isAdm = verifyIsAdm();

    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'X-User-Login-Type': isAdm
          ? UserLoginType.BusinessUnitId
          : UserLoginType.OperatorId,
      },
    });

    this.service.defaults.headers.common.Authorization = getToken();
  }

  get(path: string) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlBusinessUnit + path;
    return this.service.get(url);
  }

  put(path: string, payload: any) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlBusinessUnit + path;
    return this.service.put(url, payload);
  }

  patch(path: string, payload: any) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlBusinessUnit + path;
    return this.service.patch(url, payload);
  }

  post(path: string, payload: any) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlBusinessUnit + path;
    return this.service.post(url, payload);
  }

  delete(path: string) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlBusinessUnit + path;
    return this.service.delete(url);
  }
}
