const initialState = {
  id: '',
  code: '',
  administrator_name: '',
  accepting_orders: false,
  activated: false,
  inactivation_date: '',
  social_name: '',
  name: '',
  trade_name: '',
  phone_number: '',
  phone_number2: '',
  phone_number_commercial: null,
  email_address: '',
  calendar_description: '',
  additional_info: '',
  activation_status: 0,
  has_parking: false,
  print_label: false,
  address: {
    street: '',
    city: '',
    // Outros campos do endereço
  },
  location: {
    latitude: 0,
    longitude: 0,
  },
  opted_for_simples: false,
  document: '',
  state_registration_free: null,
  state_registration: null,
  payment_day: 0,
  payment_condition: null,
  bank_data: {
    account_number: '',
    account_agency: '',
    account_type: 0,
    bank_number: 0,
    pix_type: null,
    pix_key: null,
  },
  working_hours: {
    monday_open: '',
    monday_close: '',
    tuesday_open: '',
    tuesday_close: '',
    wednesday_open: '',
    wednesday_close: '',
    thursday_open: '',
    thursday_close: '',
    friday_open: '',
    friday_close: '',
    saturday_open: '',
    saturday_close: '',
    sunday_open: null,
    sunday_close: null,
    hollyday_open: null,
    hollyday_close: null,
  },
  break_hours: {
    break_time: false,
    break_time_hours_start: '',
    break_time_hours_end: '',
  },
  accepted_service_term: false,
  legal_entity_type: null,
  days_to_inactivate: 0,
  active_login: true,
  fee_code: '',
  hidden: false,
  created_date: '',
  password_expired: false,
  last_password_update: '',
  next_password_update: '',
  accept_reverse_labeless: false,
  new_drop_flow: false,
  already_checked: false,
};

export const GetPudoResponseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PUDO_DATA':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const updatePudoData = (pudoData) => ({
  type: 'UPDATE_PUDO_DATA',
  payload: pudoData,
});
