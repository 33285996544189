enum EnumAlert {
  Error = 1,
  Warning = 2,
  Success = 3,
}

enum EnumStateCode {
  Draft = 1,
  Ready = 2,
  Canceled = 3,
  Expired = 4,
  Dropped = 5,
  InTransit = 6,
  Delivered = 7,
  ToCollect = 8,
  CollectedByCarrier = 9,
  DevolutionDropped = 10,
  OrderReturned = 11,
  DevolutionInTransit = 12,
  ProcessingDrop = 13,
}

enum EnumServiceType {
  Reverse = 1,
  Delivery = 2,
}

enum EnumDocumentType {
  Invoice = 1,
  ContentStatement = 2,
}

enum EnumScenario {
  Scenario1 = 1,
  Scenario2 = 2,
  Scenario3 = 3,
  Scenario4 = 4,
  Scenario6 = 6,
}

enum EnumTicketType {
  Confrontation = 1,
  Collect = 2,
  Sac = 3,
  Post = 4,
  Complaint = 5,
  Suggestion = 6,
  DueDateLabel = 7,
  DueDateCollect = 8,
  Financial = 9,
}

enum EnumTicketStatus {
  New = 1,
  SuccessClosed = 2,
  InAttendance = 3,
  PendingSeller = 4,
  Cancelled = 5,
  UnsuccessfullyClosed = 6,
}

enum EnumEvent {
  Create = 1,
  DroppedByClient = 2,
  DeliveredToCarrier = 3,
  DeliveredToRecipient = 4,
  PaymentApproved = 5,
  CarrierDeliveredToRecipient = 6,
  SellerLabelSentByEmail = 7,
  BarcodeSentByEmail = 8,
  DroppedByCarrier = 13,
  UpdatePaymentStatus = 14,
  CarrierInTransit = 15,
  OrderCanceled = 16,
  StuckInDraft = 17,
  DevolutionDropped = 18,
  OrderReturned = 19,
  StatusChangedByUser = 20,
  DropOffBusinessUnitChangedByUser = 21,
  OrderCanceledByUser = 22,
  CanceledByCarrier = 23,
  DeliveredToDistributionCenter = 24,
  DevolutionCanceled = 25,
  RemovedFromPlatformPickup = 26,
  OrderDropProcessing = 29,
}

enum PaymentType {
  Credit = 1,
  Debit = 2,
}

enum AccountType {
  ContaCorrente = 1,
  Poupança = 2,
}

enum BusinessUnitActivationStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

enum LegalEntityType {
  ME = 1,
  EPP = 2,
  MEI = 3,
  None = 4,
}

enum EnumPaymentStatus {
  None = 0,
  Pending = 1,
  Approved = 2,
  Canceled = 3,
  Rejected = 4,
  Reversal = 5,
  Expired = 6,
}

enum UserLoginType {
  BusinessUnitId = 1,
  ClientId = 2,
  MarketplaceId = 3,
  UserId = 4,
  OperatorId = 6,
}

enum CreatedByType {
  Marketplace = 1,
  Client = 2,
}

enum RequestOriginType {
  App = 1,
  web = 2,
}

const EXCEPTIONS = {
  AnotherDropOffBusinessUnit: 'another_drop_off_business_unit',
  AnotherPickUpBusinessUnit: 'another_pickup_business_unit',
  LabelessAlreadyAttached: 'labeless_already_attached',
  BusinessUnitToFarOriginalDistance: '010',
  BusinessUnitNotInMarketplaceList: '011',
  UnexpectedError: '000',
  AnotherTo: 'another_to',
  OrderDropProcessing: 'order_drop_processing',
};

enum PlatformsOrderFetcher {
  Drops = 0,
  Shopee = 1,
  Reversa = 2,
}

enum PlatformFetcherOrderStatus {
  Pending = 1,
  Failed = 2,
  Completed = 3,
  Dropped = 4,
  PickupReady = 5,
  PickupPlatformFailed = 6,
  DeliveredToCarrier = 7,
  PickupPending = 8,
  PickupDropsFailed = 9,
}

enum PlatformFetcherOrderError {
  FetchError = 1,
  OrderNotFound = 2,
  OrderAlreadyDroppedOnPlatform = 3,
  OrderAlreadyOnSystem = 4,
  LayoutChanged = 5,
  ConvertingOrderScenario6 = 6,
  DropInPlatform = 7,
  DropInPudoSystem = 8,
  Authentication = 9,
  SetPickupError = 10,
  RemovePickupError = 11,
  CompletePickupError = 12,
}

const DeliveryType = {
  ordersFinalConsumer: 'ordersFinalConsumer',
  ordersCarrier: 'ordersCarrier',
  shopee: 'shopee',
  deliveryShopee: 'deliveryShopee',
  createTO: 'createTO',
  pickupBagList: 'pickupBagList',
};

const BtnSelectedType = {
  senderShopee: 'senderShopee',
};

enum PlatformFetcherOrderTOStatus {
  Active = 1,
  Completed = 2,
}

enum OrderReadAction {
  Pickup = 1,
  Drop = 2,
}

enum OrderReadError {
  OrderNotFound = 1,
  OrderDropAlreadyInProgress = 2,
  OrderDropProcessing = 3,
  AnotherDropOffBusinessUnit = 4,
  AnotherPickUpBusinessUnit = 5,
  MultipleOrders = 6,
  DevolutionNotEnabledToClient = 7,
  BusinessUnitNotAcceptingOrders = 8,
  BusinessUnitNotEnabledInNewDropFlow = 9,
  MarketplaceNotFound = 10,
  SellerNotFound = 11,
  DroppedByCarrierBusinessUnitIdInvalid = 12,
  PickupBusinessUnitIdInvalid = 13,
  PickupBusinessUnitInactive = 14,
  BusinessUnitNotInMarketplaceInclusiveList = 15,
  DevolutionNotAllowed = 16,
  OrderAlreadyDropped = 17,
  ConfirmationRequired = 18,
  SignatureRequired = 19,
  ConfirmationNameDocumentReasonRequired = 20,
  QuotesNotFound = 21,
  InvalidFileSize = 22,
  FailedToUploadToBlob = 23,
  OrderDropNotInProgress = 24,
  ActionTokenNotFound = 25,
  ActionTokenInvalid = 26,
  OrderDropEventInProgressNotFound = 27,
}

enum RequestType {
  App = 1,
  Web = 2,
}

export {
  EnumAlert,
  EnumStateCode,
  EnumServiceType,
  EnumDocumentType,
  EnumScenario,
  EnumTicketType,
  EnumTicketStatus,
  EnumEvent,
  PaymentType,
  AccountType,
  BusinessUnitActivationStatus,
  LegalEntityType,
  EnumPaymentStatus,
  UserLoginType,
  CreatedByType,
  RequestOriginType,
  EXCEPTIONS,
  PlatformsOrderFetcher,
  PlatformFetcherOrderStatus,
  PlatformFetcherOrderError,
  DeliveryType,
  BtnSelectedType,
  PlatformFetcherOrderTOStatus,
  OrderReadAction,
  OrderReadError,
  RequestType,
};
