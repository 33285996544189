// @flow
import React, { type ReactElement } from 'react';
import classNames from 'classnames';

type SpinnerProps = {
    tag?: keyof JSX.IntrinsicElements,
    className?: string,
    size?: 'lg' | 'md' | 'sm',
    type?: 'bordered' | 'grow',
    color?: string,
    children?: any,
};

/**
 * Spinner
 */
const Spinner = ({
    tag = 'div',
    className,
    size = 'md',
    type = 'bordered',
    color = 'secondary',
    children = null,
}: SpinnerProps): ReactElement<any> => {
    const Tag = tag;

    return (
        <Tag
            role="status"
            className={classNames(
                { 'spinner-border': type === 'bordered', 'spinner-grow': type === 'grow' },
                [`text-${color}`],
                { [`avatar-${size}`]: size },
                className
            )}>
            {children}
        </Tag>
    );
};

export default Spinner;
